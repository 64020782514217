import { Button } from 'antd'
import { B2CService } from 'ecpf/services/b2cService'
import useAuthLayout from 'hooks/useAuthLayout'
import FullScreenLoading, { FullScreenPageLogo } from 'layouts/FullScreenLoading/FullScreenLoading'
import React, { useCallback, useEffect, useState } from 'react'
import imagesUrls from 'utils/imagesUrls'

const SigninB2CCheckUserECPF = () => {
  const { handleSigninByAuth } = useAuthLayout()

  const parsedUrl = new URL(window.location.href)
  const fragment = parsedUrl.hash.substring(1)

  const searchParams = new URLSearchParams(fragment)
  const id_token = searchParams.get('id_token') as string

  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const onLoginUser = useCallback(
    async (id_token: string) => {
      try {
        setLoading(true)

        const b2cService = new B2CService()
        const response = await b2cService.login({ id_token })
        const auth = response.data.data

        if (auth?.token) {
          await handleSigninByAuth(auth)
        }
      } catch (err) {
        setErrorMessage(err?.message)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    onLoginUser(id_token)
  }, [id_token])

  if (errorMessage) {
    return (
      <FullScreenLoading.Wrapper>
        <FullScreenLoading.contentContainer>
          <FullScreenPageLogo logo={imagesUrls.rodobensLogoLight} />
          <FullScreenLoading.description description={errorMessage}/>

          <Button
            type='primary'
            loading={loading}
            onClick={() => {
              if (id_token) {
                onLoginUser(id_token)
              }
            }}
          >
            Tentar novamente
          </Button>
        </FullScreenLoading.contentContainer>

      </FullScreenLoading.Wrapper>
    )
  }

  return (
    <FullScreenLoading.Wrapper>
      <FullScreenLoading.LogoContent logo={imagesUrls.rodobensLogoLight} />
    </FullScreenLoading.Wrapper>
  )
}

export default SigninB2CCheckUserECPF
