import { Button } from 'antd'
import { LoginEGIService } from 'egi/services/LoginEGIService'
import useAuthLayout from 'hooks/useAuthLayout'
import FullScreenLoading, { FullScreenPageLogo } from 'layouts/FullScreenLoading/FullScreenLoading'
import React, { useCallback, useEffect, useState } from 'react'
import imagesUrls from 'utils/imagesUrls'

const LoginSSO = () => {
  const { handleSigninByAuth } = useAuthLayout()

  const urlParams = new URLSearchParams(window.location.search)
  const ssoToken = urlParams.get('ssoToken')

  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const onLoginUser = useCallback(
    async (ssoToken: string | null) => {
      try {
        setLoading(true)

        if (!ssoToken) throw new Error('Token não encontrada')

        const ssoService = new LoginEGIService()
        const response = await ssoService.ssoLogin({ ssoToken })
        const auth = response.data.data?.signin

        if (auth?.token) {
          await handleSigninByAuth(auth)
        }
      } catch (err) {
        setErrorMessage(err?.message)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    onLoginUser(ssoToken)
  }, [ssoToken])

  if (errorMessage) {
    return (
      <FullScreenLoading.Wrapper>
        <FullScreenLoading.contentContainer>
          <FullScreenPageLogo logo={imagesUrls.rodobensLogoLight} />

          <FullScreenLoading.description description={errorMessage}/>

          <Button
            type='primary'
            loading={loading}
            onClick={() => {
              onLoginUser(ssoToken)
            }}
          >
            Tentar novamente
          </Button>
        </FullScreenLoading.contentContainer>

      </FullScreenLoading.Wrapper>
    )
  }

  return (
    <FullScreenLoading.Wrapper>
      <FullScreenLoading.LogoContent logo={imagesUrls.rodobensLogoLight} />
    </FullScreenLoading.Wrapper>
  )
}

export default LoginSSO
